import { graphql } from "gatsby";
import { url } from "inspector";
import React, { FunctionComponent } from "react";
import { ColorTheme } from "../../../data/enum/ColorTheme";
import { ContentstackAsset } from "../../../data/types/Contentstack";
import { useIsMobile } from "../../../hooks/useIsMobile";
import DetailLine from "../../general/borders/DetailLine/DetailLine";
import TitleCopyWrapper from "../../general/borders/TitleCopyWrapper/TitleCopyWrapper";
import SectionTitle from "../../general/SectionTitle/SectionTitle";
import styles from "./ImageSupport.module.scss";
import bg from "../../../assets/images/bg_support.jpg";
import bg_mobile from "../../../assets/images/bg_support_m.jpg";
export interface ImageSupportProps {
}

export const ImageSupport: FunctionComponent<ImageSupportProps> = ({ }) => {
  const isMobile = useIsMobile();
  return (
    <section className={styles.imageSupport}>
      <img className={`${styles.bg}`} src={`${isMobile ? bg_mobile : bg}`} alt="bg" />
    </section>
  );
};

