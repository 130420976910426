import { graphql, PageProps } from "gatsby";
import React, { FunctionComponent } from "react";
import { ImageSupport } from "../components/block/ImageSupport/ImageSupport";
import { ContentstackArticle } from "../data/types/Contentstack";
import { ContentstackHomepage } from "../data/types/ContentstackPages";
import pageData from '../json/page-data.json';
import Zendesk from "react-zendesk";
import { ZendeskAPI } from "react-zendesk";
import { useIsMobile } from "../hooks/useIsMobile";
const ZENDESK_KEY = "f7157635-9fd6-4c26-9776-9feb175cd515";

const setting = {
  color: {
    theme: "#000"
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help"
    }
  },
  webWidget: {
    contactForm: {
      ticketForms: [{ id: 360006679193 }, { id: 1500000042921 }, { id: 360006679213 }, { id: 1500000060001 }, { id: 360006679233 }, { id: 1500000042941 }, { id: 360005696474 }, { id: 1500000046002 }, { id: 360005749574 }]
    }
  }
};

interface SupportProps {
  allContentstackHomepage: { nodes: Array<ContentstackHomepage> };
  allContentstackArticles: {
    articles: Array<ContentstackArticle>;
  };
}
const SupportPage: FunctionComponent<PageProps<SupportProps>> = ({
}) => {
  const isMobile = useIsMobile();
  // var enablePopupSupport = typeof window !== `undefined` && window.location.pathname.match("hotrokhachhang") ? true : false;

  const {
    allContentstackHomepage: {
      nodes: [{ registrationHero, newsletterSignup, news, gameOverview, gameImageData, gameVideoData, imageSlider }],
    },
    allContentstackArticles: { articles },
  } = pageData.result.data
  return (<>
    <img style={{ width: `${isMobile ? "20vw" : "8vw"}`, position: "absolute", top: "1vw", left: "2vw", zIndex: 2 }} src="https://lor.zing.vn/thoa-thuan-nguoi-dung_files/rule.jpg" alt="" title=""></img>
    {/* <SupportModal enable={enablePopupSupport} /> */}
    <Zendesk zendeskKey={ZENDESK_KEY} {...setting} onLoaded={
      () => {
        ZendeskAPI("webWidget", "setLocale", "vi");
        ZendeskAPI("webWidget", "open");
      }
    } />
    <ImageSupport />
  </>
  )
};


export const query = graphql`
  query SupportQuery {
    pageMetadata: pageMetaData {
      title
      description
      image_url
    }
  }
`;

export default SupportPage;
